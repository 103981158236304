<template>
    <div class="vue-box">
        <div class="header">
            <el-link @click="sa_admin.changeTo('project-list')"> 商务管理</el-link>
            <div class="header-item">/</div>
            <el-link> {{m.name}} </el-link>
        </div>

        <!-- 参数栏 -->
        <div class="c-panel">

            <div class="title">基础信息</div>

            <el-descriptions :column="2" border>
                <el-descriptions-item label="项目名称">{{m.name}}({{m.code}})</el-descriptions-item>
                <el-descriptions-item label="当前状态">{{m.stateEnum}}</el-descriptions-item>
                <el-descriptions-item label="交付时间">{{m.finishDate}}</el-descriptions-item>
                <el-descriptions-item label="创建人">
                    <el-tag size="small">{{m.creatorName}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="清单下载" :span="2">

                    <div class="dowItem" v-for="item in m.rowFilesFile" :key="item.url">
                        <el-link type="primary" :href="item.url">{{item.name}}</el-link>
                    </div>
                </el-descriptions-item>

                <el-descriptions-item label="合同附件" :span="2">

                    <div class="dowItem" v-for="item in m.contactFilesFile" :key="item.url">
                        
                        <el-link type="primary" :href="item.url">{{item.name}}</el-link>
                    </div>
                </el-descriptions-item>
            </el-descriptions>

            <div class="title">合同信息</div>

            <el-collapse v-model="activeName">
                <el-collapse-item v-for="(contract,index) in contracts" :key="contract.id" :title="'合同'+ (contracts.length -index)"
                    :name="index+1">
                    <el-descriptions :column="1" border>
                        <el-descriptions-item label="合同名称">{{contract.name}}</el-descriptions-item>
                        <el-descriptions-item label="合同日期">{{contract.startDate}} 至 {{contract.endDate}}
                        </el-descriptions-item>
                        <el-descriptions-item label="甲方">{{contract.firstName}}</el-descriptions-item>
                        <el-descriptions-item label="乙方">
                            {{contract.secondName}}
                        </el-descriptions-item>
                        <el-descriptions-item label="合同内容"> {{contract.contact}}</el-descriptions-item>
                    </el-descriptions>
                </el-collapse-item>

            </el-collapse>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            activeName: 1,
            m: {},
            contracts: []
        };

    },
    created() {

    },
    mounted() {
        let params = this.sa_admin.params;
        this.m = params;
        console.log(params);
        this.sa.get("/contract/getAllByProjectId/" + params.id).then(res => {
            this.contracts = res.data;
        });

    }
};
</script>

<style scoped>
.title {
    width: 84px;

    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 29px;
    margin-top: 20px;
    margin-bottom: 15px;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
}

.el-collapse-item__header {
    font-size: 16px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600 !important;
}
.header {
    margin: 20px;
    display: flex;
    flex-direction: row;
}
.header-item {
    margin-left: 9px;
    margin-right: 10px;
    color: #c0c4cc;
    padding-top: 2px;
}

.dowItem {
    margin: 10px;
}
</style>